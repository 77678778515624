<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <span><strong>Aylık Kiralama Sayfası Ayarları</strong></span>
          </CCardHeader>
        </CCard>
        <CRow class="locationModalAddUpdateFormModal">
          <CRow class="justify-center">
            <v-col cols="10">
              <span class="font-weight-bold pb-2 mb-2">
                {{ " Dil Seçimi : " }}
              </span>
              <v-btn-toggle
                v-model="btnToggleLanguage"
                borderless
                mandatory
                style="width: 90%"
                active-class="secondary white--text  "
              >
                <v-btn
                  small
                  style="width: 50%; border-radius: 50px 0 0 50px"
                  :class="{ red: !btnToggleLanguage }"
                  class="ls-0 text-capitalize font-weight-bold"
                >
                  {{ "ingilizce" }}
                </v-btn>
                <v-btn
                  small
                  style="width: 50%; border-radius: 0 50px 50px 0"
                  :class="{ red: btnToggleLanguage }"
                  class="ls-0 text-capitalize font-weight-bold"
                >
                  {{ "almanca" }}
                </v-btn>
              </v-btn-toggle></v-col
            >
          </CRow>
          <CCol sm="12" lg="12">
            <div class="img-car-container">
              <div class="img-car-upload-delete-container">
                <div class="btn-icon-center">
                  <font-awesome-icon
                    id="btnResimYukle"
                    icon="cloud-upload-alt"
                    size="2x"
                    title="Resim Yükle"
                    @click="clickImageUpload()"
                  />
                  <font-awesome-icon
                    id="btnResimSil"
                    icon="trash-alt"
                    size="2x"
                    class="ml-4"
                    title="Resmi Sil"
                    v-if="monthlyPageUpdateForm.image !== defaultLocationImage"
                    @click="clickImageDelete()"
                  />
                </div>
                <input
                  id="inputLocationImageFile"
                  type="file"
                  class="files"
                  ref="files"
                  custom
                  v-on:change="selectImage()"
                />
              </div>
              <CImg :src="monthlyPageUpdateForm.image" fluid />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CInput
              label="Sayfa Başlığı (TR)"
              v-model="monthlyPageUpdateForm.title.tr"
            />
          </CCol>
          <CCol sm="6" v-if="!btnToggleLanguage">
            <CInput
              label="Sayfa Başlığı (EN)"
              v-model="monthlyPageUpdateForm.title.en"
            />
          </CCol>
          <CCol sm="6" v-if="btnToggleLanguage">
            <CInput
              label="Sayfa Başlığı (DE)"
              v-model="monthlyPageUpdateForm.title.de"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CInput
              label="Sayfa Açıklaması (TR)"
              v-model="monthlyPageUpdateForm.description.tr"
            />
          </CCol>
          <CCol sm="6" v-if="!btnToggleLanguage">
            <CInput
              label="Sayfa Açıklaması (EN)"
              v-model="monthlyPageUpdateForm.description.en"
            />
          </CCol>
          <CCol sm="6" v-if="btnToggleLanguage">
            <CInput
              label="Sayfa Açıklaması (DE)"
              v-model="monthlyPageUpdateForm.description.de"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <span>İçerik (TR)</span>
            <ckeditor
              v-model="monthlyPageUpdateForm.content.tr"
              class="mt-3"
            ></ckeditor>
          </CCol>
          <!--<CCol sm="6" v-if="!btnToggleLanguage">
            <span>İçerik (EN)</span>
            <ckeditor
              v-model="monthlyPageUpdateForm.content.en"
              class="mt-3"></ckeditor>
          </CCol>
          <CCol sm="6" v-if="btnToggleLanguage">
            <span>İçerik (DE)</span>
            <ckeditor
              v-model="monthlyPageUpdateForm.content.de"
              class="mt-3"
            ></ckeditor>
          </CCol> -->

          <CCol sm="6">
            <span v-if="!btnToggleLanguage">İçerik (EN)</span>
            <span v-if="btnToggleLanguage">İçerik (DE)</span>
            <ckeditor v-model="ckeditorModel" class="mt-3"></ckeditor>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol sm="6">
            <CRow>
              <CCol>
                <label>Micro Data (Türkçe)</label>
                <CCard>
                  <CCardBody>
                    <CRow
                      v-for="(data, i) in monthlyPageUpdateForm.microData.tr"
                      :key="i"
                    >
                      <CCol col="11">
                        <CRow>
                          <CCol col="12">
                            <CInput
                              placeholder="Soru"
                              v-model="data.question"
                            />
                          </CCol>
                          <CCol col="12">
                            <CTextarea
                              placeholder="Cevap"
                              v-model="data.answer"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol class="mb-3 pl-1" col="1">
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style="
                            height: 100%;
                            cursor: pointer;
                            background-color: #eee;
                            border-radius: 5px;
                          "
                          v-if="
                            monthlyPageUpdateForm.microData.tr.length - 1 == i
                          "
                          @click="microDataAdd('tr')"
                        >
                          <font-awesome-icon icon="plus" />
                        </div>
                        <div
                          v-else
                          class="d-flex align-items-center justify-content-center"
                          style="
                            height: 100%;
                            cursor: pointer;
                            background-color: #fff;
                            border-radius: 5px;
                          "
                          @click="microDataDelete(data, 'tr')"
                        >
                          <font-awesome-icon icon="trash-alt" />
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCol>

          <CCol sm="6" v-if="!btnToggleLanguage">
            <CRow>
              <CCol>
                <label>Micro Data (İngilizce)</label>
                <CCard>
                  <CCardBody>
                    <CRow
                      v-for="(data, i) in monthlyPageUpdateForm.microData.en"
                      :key="i"
                    >
                      <CCol col="11">
                        <CRow>
                          <CCol col="12">
                            <CInput
                              placeholder="Soru"
                              v-model="data.question"
                            />
                          </CCol>
                          <CCol col="12">
                            <CTextarea
                              placeholder="Cevap"
                              v-model="data.answer"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol class="mb-3 pl-1" col="1">
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style="
                            height: 100%;
                            cursor: pointer;
                            background-color: #eee;
                            border-radius: 5px;
                          "
                          v-if="
                            monthlyPageUpdateForm.microData.en.length - 1 == i
                          "
                          @click="microDataAdd('en')"
                        >
                          <font-awesome-icon icon="plus" />
                        </div>
                        <div
                          v-else
                          class="d-flex align-items-center justify-content-center"
                          style="
                            height: 100%;
                            cursor: pointer;
                            background-color: #fff;
                            border-radius: 5px;
                          "
                          @click="microDataDelete(data, 'en')"
                        >
                          <font-awesome-icon icon="trash-alt" />
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="6" v-if="btnToggleLanguage">
            <CRow>
              <CCol>
                <label>Micro Data (Almanca)</label>
                <CCard>
                  <CCardBody>
                    <CRow
                      v-for="(data, i) in monthlyPageUpdateForm.microData.de"
                      :key="i"
                    >
                      <CCol col="11">
                        <CRow>
                          <CCol col="12">
                            <CInput
                              placeholder="Soru"
                              v-model="data.question"
                            />
                          </CCol>
                          <CCol col="12">
                            <CTextarea
                              placeholder="Cevap"
                              v-model="data.answer"
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol class="mb-3 pl-1" col="1">
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style="
                            height: 100%;
                            cursor: pointer;
                            background-color: #eee;
                            border-radius: 5px;
                          "
                          v-if="
                            monthlyPageUpdateForm.microData.de.length - 1 == i
                          "
                          @click="microDataAdd('de')"
                        >
                          <font-awesome-icon icon="plus" />
                        </div>
                        <div
                          v-else
                          class="d-flex align-items-center justify-content-center"
                          style="
                            height: 100%;
                            cursor: pointer;
                            background-color: #fff;
                            border-radius: 5px;
                          "
                          @click="microDataDelete(data, 'de')"
                        >
                          <font-awesome-icon icon="trash-alt" />
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12">
            <CRow>
              <div class="d-grid gap-2 col-12 mx-auto">
                <CButton color="warning" @click="updatePage()"
                  >GÜNCELLE</CButton
                >
              </div>
            </CRow>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

import ckeditor from "ckeditor4-vue"; // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#basic-usage
Vue.use(ckeditor);

export default {
  name: "MonthlyRentalPage",
  data() {
    return {
      ENV_URL_CDN: process.env.VUE_APP_CDN_URL_MONTHLY,
      defaultLocationImage: "default.jpg",
      btnToggleLanguage: 0,
      monthlyPageUpdateForm: {
        _id: "",
        title: {
          tr: "",
          en: "",
          de: "",
        },
        description: {
          tr: "",
          en: "",
          de: "",
        },
        content: {
          tr: "",
          en: "",
          de: "",
        },
        image: "",
        files: null,
        microData: {
          tr: [
            {
              question: "",
              answer: "",
            },
          ],
          en: [
            {
              question: "",
              answer: "",
            },
          ],
          de: [
            {
              question: "",
              answer: "",
            },
          ],
        },
      },
      ckeditorModel: "",
    };
  },
  watch: {
    btnToggleLanguage: function (val) {
      this.ckeditorModel = val
        ? this.monthlyPageUpdateForm.content.de
        : this.monthlyPageUpdateForm.content.en;
    },
  },
  created() {
    let resp;
    axios
      .get(process.env.VUE_APP_API_URL + "monthly-rental-page")
      .then(async (response) => {
        resp = await response.data.pageDetail[0];
        this.monthlyPageUpdateForm = await resp;
        this.monthlyPageUpdateForm.image =
          this.ENV_URL_CDN + this.monthlyPageUpdateForm.image;
        this.ckeditorModel = this.btnToggleLanguage
          ? this.monthlyPageUpdateForm.content.de
          : this.monthlyPageUpdateForm.content.en;
      });
  },
  methods: {
    microDataAdd(lang) {
      var microData = {
        question: "",
        answer: "",
      };
      this.monthlyPageUpdateForm.microData[lang].push(microData);
    },
    microDataDelete(val, lang) {
      Vue.swal
        .fire({
          icon: "question",
          text: "Silmek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var item = this.monthlyPageUpdateForm.microData[lang].indexOf(val);

            this.monthlyPageUpdateForm.microData[lang].splice(item, 1);
          }
        });
    },
    clickImageDelete() {
      // TODO clickImageDelete
      this.monthlyPageUpdateForm.image =
        this.ENV_URL_CDN + this.defaultLocationImage;
      this.monthlyPageUpdateForm.files = null;
      document.getElementById("inputLocationImageFile").value = "";
    },
    clickImageUpload() {
      document.getElementById("inputLocationImageFile").click();
    },

    selectImage() {
      // TODO selectImage
      const _this = this;
      const fileImage = this.$refs.files.files[0];

      if (fileImage.size > 1048576) {
        Vue.swal({
          icon: "error",
          title: "Lokasyon Ekle - Resim",
          html: "Maksimum 1 MB resim yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      } else {
        if (
          fileImage.type === "image/png" ||
          fileImage.type === "image/jpg" ||
          fileImage.type === "image/jpeg"
        ) {
          let reader = new FileReader();

          reader.onload = function (e) {
            _this.monthlyPageUpdateForm.image = e.target.result;
            _this.monthlyPageUpdateForm.files = fileImage;
          };
          reader.readAsDataURL(fileImage);
        } else {
          Vue.swal({
            icon: "error",
            title: "Lokasyon Ekle - Resim",
            html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        }
      }
    },

    updatePage() {
      var formData = new FormData(); // TODO Form data

      this.btnToggleLanguage
        ? (this.monthlyPageUpdateForm.content.de = this.ckeditorModel)
        : (this.monthlyPageUpdateForm.content.en = this.ckeditorModel);

      if (
        this.monthlyPageUpdateForm.files &&
        this.monthlyPageUpdateForm.files.size > 0
      )
        formData.append("image", this.monthlyPageUpdateForm.files);
      else if (
        this.monthlyPageUpdateForm.image ===
        this.ENV_URL_CDN + this.defaultLocationImage
      )
        formData.append("image", this.defaultLocationImage);

      formData.append(
        "title",
        JSON.stringify(this.monthlyPageUpdateForm.title)
      );
      formData.append(
        "description",
        JSON.stringify(this.monthlyPageUpdateForm.description)
      );
      formData.append(
        "content",
        JSON.stringify(this.monthlyPageUpdateForm.content)
      );
      formData.append(
        "microData",
        JSON.stringify(this.monthlyPageUpdateForm.microData)
      );

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "admin/monthly-rental-page/" +
            this.monthlyPageUpdateForm._id,
          formData
        )
        .then((result) => {
          Vue.swal({
            icon: result.data.result,
            title: "Sayfa Güncelleme",
            html: result.data.message,
            confirmButtonText: "Tamam",
          });
        })
        .catch((err) => {
          alert("Hata!");
        });
    },
  },
};
</script>

<style scoped></style>
